<template>
    <div v-if="$ability.can('read', this.$route.meta.resource)">
        <b-overlay :show="show" rounded="sm">
            <b-card no-body class="mb-0">


                <div class="m-2">
                    <flat-pickr id="currentDate" v-model="currentDate" class="form-control" />
                    <div>
                        <b-tabs content-class="mt-3" fill>
                            <b-tab v-for="(item, index) in tabs" :key="index" :title="item.name"
                                :active="typeTime == item.value ? true : false" @click="changetypeTime(item.value)">
                                <ItemProduct v-if="typeTime === item.value" :type-time.sync="typeTime"
                                    :current-date.sync="currentDate" :store-module-name="STORE_MODULE_NAME" />
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </b-card>
        </b-overlay>
    </div>
</template>
  
<script>
import {
    BFormGroup,
    BCard, BRow, BCol,
    BTab,
    BTabs,
    BImg,
    BAvatar,
    BFormInput, BButton,
    BTable, BPagination, BDropdown, BOverlay,
    BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/flashSale'
import debounce from 'debounce'
import flatPickr from 'vue-flatpickr-component'
import ItemProduct from './ItemProduct.vue'

const STORE_MODULE_NAME = 'flashSale'
import moment from 'moment'

export default {
    components: {
        ItemProduct,
        BFormGroup,
        BTab,
        BTabs,
        BCard,
        BRow,
        BCol,
        // eslint-disable-next-line vue/no-unused-components
        BImg,
        // eslint-disable-next-line vue/no-unused-components
        BAvatar,
        BFormInput,
        BButton,

        BTable,
        BPagination,
        BDropdown,
        BDropdownItem,
        BOverlay,
        flatPickr,
        // BFormFile,
        // BBadge,
        // BLink,
        // BMedia,
        // BAvatar,
        // eslint-disable-next-line vue/no-unused-components
        vSelect,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            avatarText,
            show: false,
            currentDate: moment().format('YYYY-MM-DD'),
            typeTime: 'tab1',
            tabs: [
                {
                    name: '00:00 - 11:59',
                    value: 'tab1',
                },
                {
                    name: '12:00 - 17:59',
                    value: 'tab2',
                },
                {
                    name: '18:00 - 20:59',
                    value: 'tab3',
                },
                {
                    name: '21:00 - 23:59',
                    value: 'tab4',
                },
            ],
        }
    },
    created() {
        // Register module
        if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    },
    destroyed() {
        if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    },
    setup() {
        return { STORE_MODULE_NAME }
    },
    methods: {
        changetypeTime(typeTime) {
            this.typeTime = typeTime
        },

        showToast(variant, position, text, icon, title) {
            this.$toast(
                {
                    component: ToastificationContent,
                    props: {
                        title,
                        icon,
                        text,
                        // icon: 'InfoIcon',
                        // text: 'I do not think that word means what you think it means.',
                        variant,
                    },
                },
                {
                    position,
                },
            )
        },
    },
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.v-select-custom {
    display: inline-block;
    width: 50;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.b-col-custom {
    margin-top: 0.5rem;
}
</style>
  